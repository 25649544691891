// extracted by mini-css-extract-plugin
export const section = "impact-module--section--3gng4";
export const bgs = "impact-module--bgs--3p_Qk";
export const bg = "impact-module--bg--3bzxH";
export const bgimg = "impact-module--bgimg--3bdJ0";
export const flex = "impact-module--flex--3zpuE";
export const icon = "impact-module--icon--398em";
export const impact = "impact-module--impact--3RY8h";
export const left = "impact-module--left--1FJly";
export const right = "impact-module--right--8SaMN";
export const h1 = "impact-module--h1--351s7";
export const h2 = "impact-module--h2--1SND_";
export const selectbox = "impact-module--selectbox--37Nuw";
export const select = "impact-module--select--IKTyR";
export const txt = "impact-module--txt--1A1Cg";
export const snapshot = "impact-module--snapshot--27Tkw";
export const snaph2 = "impact-module--snaph2--3vBX3";
export const growbox = "impact-module--growbox--3dKwW";
export const snapbox = "impact-module--snapbox--6MEM7";
export const snap = "impact-module--snap--267lj";
export const snaptxt = "impact-module--snaptxt--2JMlT";
export const snapicon = "impact-module--snapicon--3rlVK";
export const snapiconstroke = "impact-module--snapiconstroke--2Ixk4";
export const svgrotate = "impact-module--svgrotate--26HdN";
export const stikybox = "impact-module--stikybox--B5FTR";
export const stikyboxblock = "impact-module--stikyboxblock--35CgZ";
export const back = "impact-module--back--oKB-a";
export const returntxt = "impact-module--returntxt--12B5v";