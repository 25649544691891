import React from 'react'
import Impact from '../components/impact';
import data from '../data/data.json';
import '../styles/index.css'
// import withLocation from "../components/withLocation"

const ImpactPage = () => {
  return (
  <div className={`ubs`}>
    <Impact 
      data={data}
    />
  </div>
)}

// export default withLocation(ImpactPage)
export default ImpactPage
